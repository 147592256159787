<template>
    <div class="PromotionOne">
        <div class="PromotionOneHeader">
            <span class="txt">基础规则</span>
            <div class="name">
                <span>优惠券名称</span>
                <el-input v-model.trim="couponInput" style="width: 300px;margin-left: 10px"></el-input>
            </div>
        </div>
        <div class="PromotionOneTable">
            <el-table :data="tableData" border style="width: 100%;flex: 1;margin-top: 30px;border: 1px solid #DCDCDC" :cell-style="{height: '130px',color:'#333333', fontSize:'14px',fontFamily:'PingFang SC',fontWeight:500}" :header-cell-style="{fontWeight: '500', color: '#333333', background: '#F5F5F5',height: '46px'}">
                <el-table-column label="设置优惠门槛" prop="">
                    <template slot-scope="scope">
                        <div class="discounts">
                            <el-radio v-model="radio" label="1">
                                <span>满</span>
                                <el-input oninput="value=value.replace(/[^\d]/g,'')" v-model.number="manInput" style="width: 100px;margin: 0 10px"></el-input>
                                <span>元,</span>
                                <span>减</span>
                                <el-input v-model.number="jianInput" @keyup.native="checkNumber(jianInput)" style="width: 100px;margin: 0 10px"></el-input>
                                <span>元</span>
                            </el-radio>
                            <div class="addStage">
<!--                                <i class="iconfont">&#xe727;</i>-->
<!--                                <span @click="newlyStage">新增阶段</span>-->
                            </div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="table2">
                <div class="ParticipateIn" style="margin-top: 68px">
                    <span style="color:#FF0000;font-size: 16px">*</span>
                    <span style="margin-left: 4px">商品参与方式</span>
                    <el-radio-group v-model="radio3" style="margin-left: 20px">
                        <el-radio :label="1">部分参与</el-radio>
                        <el-radio :label="2">全店参与</el-radio>
                    </el-radio-group>
                </div>
                <div class="ParticipateBtn">
                    <el-button @click="toBack">上一步</el-button>
                    <el-button v-if="radio3 == '1'" style="background: #FD4446;border-color: #FD4446;color: #ffffff" @click="toSelectShop">选择主商品</el-button>
                    <el-button v-if="radio3 == '2'" style="background: #FD4446;border-color: #FD4446;color: #ffffff" @click="toSucceed">提交</el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    export default {
        name: "CouponOne",
        data(){
            return {
                tableData:[{}],
                tableData2:[],
                radio:'1',
                radio2:'1',
                radio3: null,
                //优惠券名称
                couponInput: '',
                //满几元
                manInput: null,
                //减几元
                jianInput: null,
                //要选择的商品
                goodsList: [],
                //已选
                selectGoodsList: [],
                //优惠券id
                couponId: Number(this.$route.query.id) || 0,
            }
        },
        mounted() {
            this.getGoodsList();
            this.editCoupon();
        },
        computed: {
            menus() {
                return this.$store.getters.dataMenuList;
            }
        },
        methods: {
            ...mapActions([
                'setDataMenusList'
            ]),
            checkNumber (val) {
                if (isNaN(Number(val))) {
                    this.jianInput = '';
                    return;
                }
                if (Number(val) < 0) {
                    this.$message.warning('满减不能小于0');
                    this.jianInput = '';
                    return;
                }
            },
            // 更新状态
            operation_updateStatus(index1, index2,  status) {
                let param = {
                    offset1: Number(index1),
                    offset2: Number(index2),
                    status: Number(status),
                }
                this.$http.axiosGetBy(this.$api.data_setUserOpList, param, (res) => {
                    if (res.code === 200) {
                        this.setDataMenusList();
                    } else {
                        this.$message.warning(res.msg);
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            //获取商品
            getGoodsList() {
                this.$http.axiosGet(this.$api.showStoreMarket, (res) => {
                    if (res.code === 200) {
                        this.goodsList = res.data.data[0].goods_list;
                        this.radio3 = 1;
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err);
                })
            },
            //编辑
            editCoupon() {
                if (this.couponId) {
                    const _this = this;
                    this.$http.axiosGetBy(this.$api.showStuMarket, {id: this.couponId, type: '4'}, (res) => {
                        if (res.code === 200) {
                            this.couponInput = res.data.data[0].stu_market_data[0].name;
                            let tmpl = res.data.data[0].stu_market_data[0].activity_data.data;
                            this.manInput = Number(tmpl[(tmpl.length-1)].rule.split('#')[0]);
                            this.jianInput = Number(tmpl[(tmpl.length-1)].rule.split('#')[1]);
                            this.selectGoodsList = res.data.data[0].stu_market_data[0].activity_data.data;
                            if (((this.selectGoodsList.length) - 1) === this.goodsList.length) {
                                this.radio3 = 2;
                            } else {
                                this.radio3 = 1;
                            }
                        } else {
                            this.$message({
                                message: res.msg,
                                duration: 1000,
                                type: 'warning',
                                onClose: function() {
                                    _this.$router.push('/student/operationpromotion/coupon');
                                }
                            });
                        }
                    }, (err) => {
                        console.log(err);
                    })
                }
            },
            newlyStage(){
                let obj = {
                    fullPrice:'',
                    subtractPrice:''
                };
                this.tableData2.push(obj)
            },
            deleStage(index){
                this.tableData2.splice(index,1)
            },
            toBack(){
                this.$router.go(-1)
            },
            toSelectShop(){
                if (!this.couponInput || this.couponInput === '') {
                    this.$message.warning('请输入优惠券名称');
                    return
                }
                if (this.manInput === null || this.manInput === 0 || this.jianInput === null || this.jianInput === 0 ) {
                    this.$message.warning('请输入满减金额,须为大于0的数字');
                    return
                }
                if (this.manInput <= this.jianInput) {
                    this.$message.warning('满的金额必须大于减的金额');
                    return
                }

                this.$router.push({
                    path:'/student/operationpromotion/coupontwo',
                    query: {
                        id: this.couponId
                    }
                })
                //存到缓存
                this.$lockr.set('oneStepData', {man: this.manInput, jian: this.jianInput, name: this.couponInput, id: this.couponId});
            },
            toSucceed(){
                if (this.manInput === null || this.manInput === 0 || this.jianInput === null || this.jianInput === 0 ) {
                    this.$message.warning('请输入满减金额,须为大于0的数字');
                    return
                }
                if (this.manInput <= this.jianInput) {
                    this.$message.warning('满的金额必须大于减的金额');
                    return
                }
                let formData = new FormData();
                formData.append('type', '4');
                if (this.couponInput) {
                    formData.append('name', this.couponInput);
                } else {
                    this.$message.warning('请输入优惠券名称');
                    return false;
                }
                let activityData = {};
                activityData.type = '4';
                activityData.data = [];
                let data_arr = [];
                let goods_data = {};
                this.selectGoodsList = this.goodsList;
                for (let key in this.selectGoodsList) {
                    delete this.selectGoodsList[key].create_time;
                    delete this.selectGoodsList[key].delete_time;
                    let img_url = this.selectGoodsList[key].image.url;
                    this.selectGoodsList[key].image = img_url;
                    goods_data = this.selectGoodsList[key];
                    data_arr.push({goods_data});
                }

                let activityDetail = `满${this.manInput}元减${this.jianInput}`;
                let rule = `${this.manInput}#${this.jianInput}`;
                data_arr.push({rule});
                activityData.data = data_arr
                // return
                // activityData.data.push(data_arr)
                formData.append('activity_data', JSON.stringify(activityData));
                formData.append('activity_detail', activityDetail);

                this.$http.axiosPost(this.$api.saveStudentMarket, formData, (res) => {
                    if (res.code === 200) {
                        this.$message.success(res.msg);
                        this.$router.push({
                            path: '/student/operationpromotion/coupon'
                        })
                        if (this.$common.isCompetion()) {
                            let menus = this.menus;
                            if (Number(menus[1].children[3].status) !== 2) {
                                this.operation_updateStatus(1,3,2);
                            }
                        }
                    } else {
                        this.$message.warning(res.msg);
                    }
                }, (err) => {
                    console.log(err);
                })
            },
        }
    }
</script>

<style scoped lang="scss">
    /deep/.el-button--default:hover{
        border-color: #FD4446;
        color: #FD4446;
        background: #ffffff;
    }
    /deep/.has-gutter .is-leaf{
        padding-left:40px;
    }
    /deep/.is-checked .el-radio__inner{
        border-color: #FD4446!important;
        background: #FD4446!important;
    }
    /deep/.el-radio__inner{
        width: 14px;
        height: 14px;
    }
    /deep/.is-checked .el-radio__label{
        color: #333333!important;
        font-size: 14px;
    }
    .PromotionOne{
        padding: 0 20px 50px 20px;
        .PromotionOneHeader{
            display: flex;
            flex-direction: column;
            .txt{
                font-size: 18px;
            }
            span{
                font-size: 16px;
                line-height: 1;
            }
            .name{
                margin-top: 30px;
            }
            .txt2{
                margin-top: 30px;
            }
        }
        .PromotionOneTable{
            .discounts{
                cursor: pointer;
                padding: 20px;
                position: relative;
                .addStage{
                    display: flex;
                    align-items: center;
                    color: #1E63F1;
                    position: absolute;
                    right: 30px;
                    top: 0;
                    i{
                        font-size: 16px;
                    }
                    span{
                        font-size: 14px;
                        margin-left: 10px;
                    }
                }
            }
            .table2{
                /deep/.el-table__header-wrapper{
                    display: none;
                }
                .ParticipateBtn{
                    margin-top: 57px;
                }
            }
        }
    }
</style>